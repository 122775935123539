import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { removeTimestamp } from 'common/utils/date.util';
import type { AddressFormSectionInputs } from 'common/types/account-info-form-types';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import { RHFSelectInput } from 'components/form-elements/rhf-select-input.component';
import { RHFHiddenInput } from 'components/form-elements/rhf-hidden-input.component';
import { useGooglePlacesAutocomplete } from 'hooks/use-google-places-autocomplete/use-google-places-autocomplete.hook';
import { useMonthsAhead } from 'hooks/use-months-ahead/use-months-ahead.hook';
import {
  ADDRESS_FORM_ELEMENT_IDS,
  ADDRESS_TYPES,
} from 'common/constants/account-info';

type AddressFormProps = {
  addressType: string;
  defaultValues?: AddressFormSectionInputs;
};

// Check if fields with a specific substring are dirty
const hasDirtyFields = (
  dirtyFields: { [key: string]: string },
  substring: string,
) => {
  const regex = new RegExp(substring, 'i');
  return Object.keys(dirtyFields).some((item) => regex.test(item));
};

export const AddressForm = ({
  addressType,
  defaultValues,
}: AddressFormProps) => {
  const { formatMessage } = useIntl();

  // Initialize React Hook Form
  const {
    getValues,
    formState: { dirtyFields },
    trigger,
  } = useFormContext();

  // Add mailing_ or residential_ to form field ids
  const PREFIXED_ADDRESS_IDS = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(ADDRESS_FORM_ELEMENT_IDS).map(([key, value]) => [
          key,
          `${addressType}_${value}`,
        ]),
      ),
    [addressType],
  );

  // Connect Google Places autocomplete
  const {
    displayAddressInputRef,
    streetAddressInputRef,
    cityInputRef,
    stateInputRef,
    zipCodeInputRef,
  } = useGooglePlacesAutocomplete(PREFIXED_ADDRESS_IDS.DISPLAY_ADDRESS);

  // Get 2 future months for effective date dropdown
  const residentialAddressEffectiveDateOptions = useMonthsAhead(2, true);
  const residentialStateOriginal = defaultValues?.residential_state;
  const onGooglePlaceChanged = useCallback(() => {
    trigger(PREFIXED_ADDRESS_IDS.DISPLAY_ADDRESS);
  }, [trigger, PREFIXED_ADDRESS_IDS]);

  // Trigger display address field validation after Google Places updated the values
  useEffect(() => {
    window.addEventListener('place_changed', onGooglePlaceChanged);
    return () =>
      window.removeEventListener('place_changed', onGooglePlaceChanged);
  }, [onGooglePlaceChanged]);

  return (
    <>
      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.DISPLAY_ADDRESS}
        ref={displayAddressInputRef}
        label={formatMessage({ id: 'STR_STREET_ADDRESS' })}
        required
        // Validate same state for residential address
        {...(addressType === ADDRESS_TYPES.RESIDENTIAL && {
          registerOptions: {
            validate: {
              sameState: () => {
                const { residential_state: residentialStateInput } =
                  getValues();
                // Validate only if the state exists; otherwise valid by default
                if (residentialStateOriginal) {
                  return (
                    (residentialStateInput &&
                      residentialStateInput === residentialStateOriginal) ||
                    formatMessage(
                      { id: 'STR_STATE_ERROR' },
                      { stateCode: residentialStateOriginal },
                    )
                  );
                }
                return true;
              },
            },
          },
        })}
      />
      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.STREET_ADDRESS_2}
        label={formatMessage({ id: 'STR_STREET_ADDRESS_2' })}
      />
      <RHFHiddenInput
        id={PREFIXED_ADDRESS_IDS.STREET_ADDRESS}
        ref={streetAddressInputRef}
      />
      <RHFHiddenInput id={PREFIXED_ADDRESS_IDS.CITY} ref={cityInputRef} />
      <RHFHiddenInput
        id={PREFIXED_ADDRESS_IDS.ZIP_CODE}
        ref={zipCodeInputRef}
      />
      <RHFHiddenInput id={PREFIXED_ADDRESS_IDS.STATE} ref={stateInputRef} />

      {addressType === ADDRESS_TYPES.RESIDENTIAL &&
        hasDirtyFields(dirtyFields, ADDRESS_TYPES.RESIDENTIAL) && (
          <RHFSelectInput
            id={PREFIXED_ADDRESS_IDS.EFFECTIVE_DATE}
            label={formatMessage({ id: 'STR_EFFECTIVE_DATE' })}
            options={residentialAddressEffectiveDateOptions}
            required
          />
        )}

      {addressType === ADDRESS_TYPES.MAILING &&
        hasDirtyFields(dirtyFields, ADDRESS_TYPES.MAILING) && (
          <RHFTextInput
            // Allow only future dates.
            min={removeTimestamp(new Date().toISOString())}
            type="date"
            id={PREFIXED_ADDRESS_IDS.EFFECTIVE_DATE}
            label={formatMessage({ id: 'STR_EFFECTIVE_DATE' })}
            required
          />
        )}
    </>
  );
};
