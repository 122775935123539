export const ariaDescribedbyProp = ({
  id,
  error,
  hint,
}: {
  id: string;
  error?: boolean | string;
  hint?: string;
}) => {
  const ariaDescribedBy: string[] = [];
  if (error) ariaDescribedBy.push(`${id}-error`);
  if (hint) ariaDescribedBy.push(`${id}-hint`);
  return ariaDescribedBy.length > 0
    ? {
        'aria-describedby': ariaDescribedBy.join(' '),
      }
    : null;
};
