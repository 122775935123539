import { type PropsWithChildren } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { Redirect } from '@leagueplatform/routing';
import { useAuth } from '@leagueplatform/auth-utils';

export const AuthorizedRoutes = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated, error } = useAuth({
    redirectToLogInWhenSignedOut: true,
  });

  if (error) return <Redirect push to="/" />;
  if (!isAuthenticated) return <LoadingIndicator />;

  return <>{children}</>;
};
