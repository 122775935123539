export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date instanceof Date && !Number.isNaN(date.valueOf());
};

export const removeTimestamp = (dateString: string) => {
  // Check if valid date and expected number of characters
  // e.g. '2025-01-01T05:00:00.000Z'
  if (isValidDate(dateString) && dateString?.length === 24) {
    return dateString.split('T')[0];
  }
  return dateString;
};
