import { useIntl } from '@leagueplatform/locales';
import { DATE_FORMAT } from '@leagueplatform/web-common';
import { removeTimestamp } from 'common/utils/date.util';

const getFutureDate = (date: Date, monthsAhead: number) => {
  const result = new Date(date);
  // first day of the month
  result.setDate(1);
  // midnight
  result.setHours(0, 0, 0, 0);
  // x months in the future
  result.setMonth(result.getMonth() + monthsAhead);
  return result;
};

export const useMonthsAhead = (months = 2, dateOnly?: boolean) => {
  const { formatDate } = useIntl();
  const datesArray = [];
  const getDateValues = (newDate: Date) => ({
    value: dateOnly
      ? removeTimestamp(newDate.toISOString())
      : newDate.toISOString(),
    label: formatDate(newDate, DATE_FORMAT),
  });

  // Generate first of the month in the future based on current date
  const now = new Date();
  for (let i = 0; i < months; i += 1) {
    datesArray.push(getDateValues(getFutureDate(now, i + 1)));
  }
  return datesArray;
};
