import { useUserName } from 'hooks/user-user-name/use-user-name.hook';
import { useUserDropdownConfig } from './use-user-dropdown-config';

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { userFirstName } = useUserName();
  const dropdownMenuItemProps = {
    userFirstName,
    menuItemsConfig: useUserDropdownConfig(handleDeleteAccount),
  };

  return { data: dropdownMenuItemProps };
};
