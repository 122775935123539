import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { logout } from 'common/utils/logout';

export const AuthViewLogout = () => {
  useEffect(() => {
    logout();
  }, []);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingIndicator />;
};
