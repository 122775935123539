import { forwardRef } from 'react';
import mergeRefs from 'merge-refs';
import { useFormContext } from '@leagueplatform/web-common';
import { useRequiredErrorMessage } from 'hooks/use-required-error-message.hook';
import { Select, type GDSSelectProps } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { RHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';
import { ariaDescribedbyProp } from 'common/utils/get-ariadescribedby-prop';
import type { MergeWithRHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';

export const RHFSelectInput = forwardRef<
  HTMLSelectElement,
  MergeWithRHFInputWrapper<GDSSelectProps>
>(
  (
    { id, label, hint, required, registerOptions, ...restComponentProps },
    ref,
  ) => {
    const { formatMessage } = useIntl();

    const requiredErrorMessage = useRequiredErrorMessage(label);

    const {
      register,
      formState: { errors },
    } = useFormContext();

    const { ref: RHFRegisterRef, ...restRHFRegisterProps } = register(id, {
      ...registerOptions,
      required: required && requiredErrorMessage,
    });

    return (
      <RHFInputWrapper id={id} label={label} hint={hint} required={required}>
        <Select
          id={id}
          {...(errors?.[id]?.message && { inputStatus: 'error' })}
          {...ariaDescribedbyProp({
            id,
            error: !!errors?.[id]?.message,
            hint,
          })}
          {...restComponentProps}
          {...restRHFRegisterProps}
          ref={mergeRefs(RHFRegisterRef, ref)}
          placeholder={formatMessage({ id: 'STR_SELECT_PLACEHOLDER' })}
        />
      </RHFInputWrapper>
    );
  },
);
