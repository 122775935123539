import { useIntl } from '@leagueplatform/locales';
import {
  StatusBadge,
  type GDSStatusBadgeProps,
  Box,
} from '@leagueplatform/genesis-core';
import type {
  LocalesKey,
  PriorAuthorizationStatuses,
} from 'common/types/types';

type AuthorizationStatusBadgeProps = {
  status: PriorAuthorizationStatuses;
  showLight?: GDSStatusBadgeProps['showLight'];
};

const AuthorizationStatusBadgeMap: Record<
  PriorAuthorizationStatuses,
  { label: LocalesKey; status: GDSStatusBadgeProps['status'] }
> = {
  approved: {
    label: 'STR_AUTHORIZATION_STATUS_APPROVED',
    status: 'success',
  },
  inProgress: {
    label: 'STR_AUTHORIZATION_STATUS_IN_PROGRESS',
    status: 'warning',
  },
  partiallyApproved: {
    label: 'STR_AUTHORIZATION_STATUS_PARTIALLY_APPROVED',
    status: 'info',
  },
  denied: {
    label: 'STR_AUTHORIZATION_STATUS_DENIED',
    status: 'error',
  },
};

export const AuthorizationStatusBadge = ({
  status,
  showLight,
}: AuthorizationStatusBadgeProps) => {
  const { formatMessage } = useIntl();
  const { label, status: badgeStatus } = AuthorizationStatusBadgeMap[status];

  return (
    <Box>
      <StatusBadge
        label={formatMessage({ id: label })}
        status={badgeStatus}
        showLight={showLight}
      />
    </Box>
  );
};
