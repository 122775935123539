import { useMemo } from 'react';
import {
  useGetUserProfile,
  type UserProfileOptions,
} from '@leagueplatform/web-common';
import { formatSnakeToCamel } from 'common/utils/snake-to-camel';
import { normalizeValue } from 'common/utils/normalize-value';
import type {
  CustomFieldsArray,
  CustomFieldObjectNormalized,
  PhoneNumbersArray,
  PhoneNumberObject,
  ScanData,
} from './types';

const normalizeCustomFields = (
  customFields: CustomFieldsArray['customFields'],
) =>
  customFields?.reduce(
    (previous, current) => ({
      ...previous,
      [formatSnakeToCamel(current.name)]: normalizeValue(current.value),
    }),
    {} as CustomFieldObjectNormalized,
  );

const normalizePhoneNumbers = (
  phoneNumbers: PhoneNumbersArray['phoneNumbers'],
) =>
  phoneNumbers?.reduce((previous, current) => {
    if (current.description)
      return {
        ...previous,
        [current.description]: {
          number: current.number,
        },
      };
    return previous;
  }, {} as PhoneNumberObject);

export const useGetScanUserProfile = (
  options?: UserProfileOptions,
  userId?: string,
) => {
  const { data, isLoading } = useGetUserProfile(options, userId);

  const scanData = data as ScanData;

  const normalizedData = useMemo(() => {
    if (scanData) {
      const normalizedCustomFields = normalizeCustomFields(
        scanData?.customFields,
      );
      const normalizedPhoneNumbers = normalizePhoneNumbers(
        scanData?.phoneNumbers,
      );

      return {
        ...scanData,
        customFields: normalizedCustomFields,
        phoneNumbers: normalizedPhoneNumbers,
      };
    }
    return scanData;
  }, [scanData]);

  return {
    isLoading,
    data: normalizedData,
  };
};
