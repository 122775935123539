import { GenericErrorPageProps } from 'components/error-page/error-page.component';
import PageNotFoundIcon from '../../assets/page-not-found.png';

export const genericErrorProps: GenericErrorPageProps = {
  headingLocaleKey: 'STR_SOMETHING_WENT_WRONG',
  bodyText: 'STR_SERVER_CONNECTION_ERROR',
  buttonLocaleKey: 'STR_RELOAD',
  isFullPage: true,
};

export const pageNotFoundProps: GenericErrorPageProps = {
  imageSrc: PageNotFoundIcon,
  headingLocaleKey: 'STR_PAGE_NOT_FOUND',
  bodyText: 'STR_SORRY_WE_CANT_FIND_PAGE',
  buttonLocaleKey: 'STR_GO_TO_HOME',
  hasGoToHomepage: true,
};
