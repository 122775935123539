export const API_PATHS = {
  COMMUNICATION_PREFERENCES: '/v1/communication-preferences',
  SELECT_OPTIONS: '/v1/menu',
};

export const CLIENT_API_PATHS = {
  PROVIDERS: (userId: string) => `/${userId}/providers`,
};

export const QUERY_KEYS = {
  COMMUNICATION_PREFERENCES: 'COMMUNICATION-PREFERENCES',
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};

export const MUTATION_KEYS = {
  COMMUNICATION_PREFERENCES: {
    PATCH: 'PATCH-COMMUNICATION-PREFERENCES',
  },
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  PROVIDERS: {
    PUT: 'PUT-PROVIDERS',
  },
  UPDATE_USER_EMAIL: 'UPDATE_USER_EMAIL',
};

export const REST_ENDPOINTS = {
  USERS: '/v1/users',
};
