import { StackItem, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { ErrorState } from '@leagueplatform/web-common-components';
import { ROUTE_PATHS } from 'common/constants/constants';
import { LocalesKey } from 'common/types/types';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import ERROR_IMAGE from 'assets/generic-error-illustration.png';

export interface GenericErrorPageProps {
  headingLocaleKey: LocalesKey;
  bodyText?: LocalesKey | string;
  buttonLocaleKey?: LocalesKey;
  imageSrc?: string;
  isFullPage?: boolean;
  hasGoToHomepage?: boolean;
}

export const GenericErrorPage = ({
  imageSrc,
  bodyText,
  headingLocaleKey,
  buttonLocaleKey,
  hasGoToHomepage,
  isFullPage,
}: GenericErrorPageProps) => {
  const history = useHistory();

  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();
  return (
    <StackLayout
      verticalAlignment="center"
      horizontalAlignment="center"
      {...(isFullPage && {
        css: {
          height: '100vh',
          backgroundColor: '$surfaceBackgroundPrimary',
        },
      })}
    >
      <StackItem
        css={{
          ...(isBelowTablet && { height: '100%' }),
          minWidth: isBelowTablet ? '100vw' : 708,
          background: '$surfaceCardSecondary',
          boxShadow: '$card',
          padding: isBelowTablet ? '$oneAndHalf' : '$twoAndHalf',
          '& img': {
            width: 176,
          },
        }}
      >
        <ErrorState
          css={{
            marginBlockStart: '$one',
            '.error-state-button-wrapper': {
              display: 'block',
              width: '100%',
            },
            '.error-state-button': {
              ...(isBelowTablet ? { width: '100%' } : { minWidth: 320 }),
              justifyContent: 'center',
            },
          }}
          imageSrc={imageSrc || ERROR_IMAGE}
          headingString={formatMessage({ id: headingLocaleKey })}
          {...(bodyText && {
            bodyString: bodyText.startsWith('STR_')
              ? formatMessage({ id: bodyText as LocalesKey })
              : bodyText,
          })}
          {...(buttonLocaleKey
            ? {
                buttonText: formatMessage({ id: buttonLocaleKey }),
              }
            : { hideButton: true })}
          {...(hasGoToHomepage && {
            onButtonClick: () => history.push(ROUTE_PATHS.HOME),
          })}
        />
      </StackItem>
    </StackLayout>
  );
};
