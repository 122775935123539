import { Environment, LeagueConfig } from '@leagueplatform/core';
import { TENANT_ID, APP_ID } from 'common/constants/constants';
import { MASONRY_WS_CONTAINERS } from 'common/constants/masonry';
import { scanHealthTheme } from 'theme/scan-theme';
import FeatureHighlightBackground from 'assets/feature-highlights-background.png';
import EN_STRING_OVERRIDES from 'locales/en.json';
import ES_STRING_OVERRIDES from 'locales/es.json';
import KO_STRING_OVERRIDES from 'locales/ko.json';
import ZH_STRING_OVERRIDES from 'locales/zh.json';
import VI_STRING_OVERRIDES from 'locales/vi.json';

export const routesConfig = {};

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_CONNECTION,
  VITE_APP_ENV,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN,
  client_id: VITE_AUTH0_CLIENT_ID,
  redirect_uri: window.location.origin,
  connection: VITE_AUTH0_CONNECTION,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL,
  url: VITE_API_URL,
  legacyRestApi: VITE_LEGACY_REST_API_URL,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const config: LeagueConfig = {
  core: {
    tenantId: TENANT_ID,
    clientId: APP_ID,
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: scanHealthTheme,
      components: { pageHeader: { shape: 'line' } },
    },
    i18n: {
      strings: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
        es: ES_STRING_OVERRIDES,
        zh: ZH_STRING_OVERRIDES,
        vi: VI_STRING_OVERRIDES,
        ko: KO_STRING_OVERRIDES,
      },
    },
  },
  assets: {
    overrides: {
      FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightBackground,
    },
  },
  careDiscovery: {
    masonryAppId: {
      left: MASONRY_WS_CONTAINERS.CARE_HOME_PAGE,
    },
    masonryAppVersion: '1.5',
  },
  __internal: {
    shouldApplyCSSReset: true,
    shouldApplyLegacyGlobalStyles: true,
  },
};
