import React, { useCallback, useEffect } from 'react';
import {
  HeadingText,
  ParagraphText,
  Modal,
  Button,
  StackLayout,
  Box,
  styled,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useForm, type FieldValues } from '@leagueplatform/web-common';
import {
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
} from 'common/constants/constants';
import SignedDocumentIllustration from 'assets/signed-document-illustration.png';
import { ResponsiveButtonRow } from 'components/responsive-button-row/responsive-button-row.component';
import { ExternalLink } from 'components/external-link/external-link.component';

interface TextCommunicationModalFormValues extends FieldValues {
  acceptTextCommunicationTerms: boolean;
}

type TextCommunicationTermsModalProps = {
  open?: boolean;
  closeModal: (userAcceptedTermsAndConditions: boolean) => void;
};

const ModalImage = styled(`img`, {
  alignSelf: 'center',
});

export const TextCommunicationTermsModal = ({
  open,
  closeModal,
}: TextCommunicationTermsModalProps) => {
  const { handleSubmit } = useForm<TextCommunicationModalFormValues>({
    defaultValues: {
      acceptTextCommunicationTerms: false,
    },
  });
  const { formatMessage } = useIntl();

  const closeModalWithoutAcceptance = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  const closeModalWithAcceptance = useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
      detail: 'accept text terms',
    });
    closeModal(true);
  }, [closeModal]);

  // Send screen loaded analytics event when the modal is opened.
  useEffect(() => {
    if (open) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.TEXT_TC_MODAL,
      });
    }
  }, [open]);

  return (
    <Modal.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) closeModalWithoutAcceptance();
      }}
    >
      <Modal.Content
        showCloseButton={false}
        width={{
          '@initial': 639,
          '@mobile': '100%',
          '@mobileLandscape': '100%',
        }}
        data-testid="text-communication-modal"
      >
        <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
          <StackLayout orientation="horizontal" horizontalAlignment="end">
            <Modal.Close>
              <Button
                onClick={closeModalWithoutAcceptance}
                quiet
                priority="tertiary"
                icon="interfaceClose"
                size="medium"
                hideLabel
              >
                {formatMessage({ id: 'STR_CLOSE' })}
              </Button>
            </Modal.Close>
          </StackLayout>

          <ModalImage src={SignedDocumentIllustration} aria-hidden alt="" />
          <>
            <Box
              css={{
                marginBlockEnd: '$oneAndHalf',
              }}
            >
              <HeadingText
                level="3"
                size="lg"
                css={{
                  marginBlockEnd: '$half',
                }}
              >
                {formatMessage({ id: 'STR_TEXT_MSG_TERMS_HEADING' })}
              </HeadingText>

              <ParagraphText
                css={{
                  '& > a': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {formatMessage(
                  {
                    id: 'STR_TEXT_MESSAGE_CONSENT',
                  },
                  {
                    termsAndConditionsLink: (
                      <TextAction as={ExternalLink} href={TERMS_OF_USE_URL}>
                        {formatMessage({ id: 'STR_TERMS_AND_CONDITIONS' })}
                      </TextAction>
                    ),
                    privacyPolicyLink: (
                      <TextAction as={ExternalLink} href={PRIVACY_POLICY_URL}>
                        {formatMessage({ id: 'STR_PRIVACY_POLICY' })}
                      </TextAction>
                    ),
                  },
                )}
              </ParagraphText>
            </Box>
            <StackLayout
              as="form"
              onSubmit={handleSubmit(closeModalWithAcceptance)}
            >
              <ResponsiveButtonRow
                primaryBtn={
                  <Button type="submit" width="fillContainer">
                    {formatMessage({ id: 'STR_ACCEPT_TERMS' })}
                  </Button>
                }
                secondaryBtn={
                  <Modal.Close>
                    <Button
                      onClick={() => {
                        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                          product_area: PRODUCT_AREA.SETTINGS,
                          screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
                          detail: 'reject text terms',
                        });

                        closeModalWithoutAcceptance();
                      }}
                      priority="secondary"
                      width="fillContainer"
                    >
                      {formatMessage({ id: 'STR_REJECT_TERMS' })}
                    </Button>
                  </Modal.Close>
                }
              />
            </StackLayout>
          </>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
