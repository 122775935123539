import { useIntl, getCurrentLocale } from '@leagueplatform/locales';
import {
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  NON_DISCRIMINATION_POLICY_URL,
} from 'common/constants/constants';
import type {
  AppLocales,
  FormLinkouts,
  AppStoreLinkProps,
  FooterLink,
} from 'common/types/types';
import APP_STORE_ZH from '../../assets/app-store/app-store-zh.png';
import APP_STORE_EN from '../../assets/app-store/app-store-en.png';
import APP_STORE_ES from '../../assets/app-store/app-store-es.png';
import APP_STORE_KO from '../../assets/app-store/app-store-ko.png';
import APP_STORE_VI from '../../assets/app-store/app-store-vi.png';
import GOOGLE_STORE_EN from '../../assets/google-play-store/google-store-en.png';
import GOOGLE_STORE_ZH from '../../assets/google-play-store/google-store-zh.png';
import GOOGLE_STORE_ES from '../../assets/google-play-store/google-store-es.png';
import GOOGLE_STORE_KO from '../../assets/google-play-store/google-store-ko.png';
import GOOGLE_STORE_VI from '../../assets/google-play-store/google-store-vi.png';

interface StoreImages {
  googlePlayStoreImage: string;
  appStoreImage: string;
}

const localeStoreImages: Record<AppLocales, StoreImages> = {
  en: {
    googlePlayStoreImage: GOOGLE_STORE_EN,
    appStoreImage: APP_STORE_EN,
  },
  zh: {
    googlePlayStoreImage: GOOGLE_STORE_ZH,
    appStoreImage: APP_STORE_ZH,
  },
  es: {
    googlePlayStoreImage: GOOGLE_STORE_ES,
    appStoreImage: APP_STORE_ES,
  },
  ko: {
    googlePlayStoreImage: GOOGLE_STORE_KO,
    appStoreImage: APP_STORE_KO,
  },
  vi: {
    googlePlayStoreImage: GOOGLE_STORE_VI,
    appStoreImage: APP_STORE_VI,
  },
};

export const getBaseLocale = <
  T extends Record<AppLocales, StoreImages | FormLinkouts>,
>(
  localeObject: T,
): AppLocales => {
  const currentLocale = getCurrentLocale();
  const localeLanguage = currentLocale.split('-')[0] as AppLocales;

  return Object.hasOwn(localeObject, localeLanguage) ? localeLanguage : 'en';
};

export function useFooterConfig() {
  const { formatMessage } = useIntl();

  const baseLocale = getBaseLocale(localeStoreImages);

  const { googlePlayStoreImage, appStoreImage } = localeStoreImages[baseLocale];

  const footerLinks: FooterLink[] = [
    {
      to: NON_DISCRIMINATION_POLICY_URL,
      target: '_blank',
      text: formatMessage({ id: 'STR_NON_DISCRIMINATION_POLICY' }),
    },
    {
      to: PRIVACY_POLICY_URL,
      target: '_blank',
      text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
    },
    {
      to: TERMS_OF_USE_URL,
      target: '_blank',
      text: formatMessage({ id: 'STR_TERMS_OF_USE' }),
    },
  ];

  const appLinks: AppStoreLinkProps[] = [
    {
      href: '#',
      alt: formatMessage({ id: 'STR_DOWNLOAD_APP_STORE' }),
      src: appStoreImage,
    },
    {
      href: '#',
      alt: formatMessage({ id: 'STR_GET_GOOGLE_PLAY' }),
      src: googlePlayStoreImage,
    },
  ];

  return {
    footerLinks,
    appLinks,
  };
}
