import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
} from 'common/constants/masonry';
import { HeroBanner } from 'components/hero-banner/hero-banner.component';

export const initDefaultWSRegistry = () => {
  MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.HERO_BANNER,
    HeroBanner,
  );
};
