import { useCallback } from 'react';
import {
  add,
  remove,
  useExitWarningDispatchContext,
} from 'components/modals/exit-warning/exit-warning.context';

export const useDirtyFormHandler = (pageHeading: string) => {
  const dispatch = useExitWarningDispatchContext();

  const addDirtyForm = useCallback(() => {
    dispatch(add(pageHeading));
  }, [dispatch, pageHeading]);

  const removeDirtyForm = useCallback(() => {
    dispatch(remove(pageHeading));
  }, [dispatch, pageHeading]);

  return { addDirtyForm, removeDirtyForm };
};
