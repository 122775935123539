import React, { useState } from 'react';
import { UserDropdownComponent } from '@leagueplatform/web-common-components';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useUserDropdownComponent } from 'hooks/use-user-dropdown/use-user-dropdown.hook';
import { AccountDeletionModal } from 'components/modals/account-deletion/account-deletion.component';

export const UserDropdown = React.memo(() => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteAccount: () => void = () => {
    setShowDeleteModal(true);
  };
  const { data } = useUserDropdownComponent(handleDeleteAccount);
  const userDropdownAnalytics = {
    name: EVENT_NAME.NAVIGATION_BAR_CLICKED,
    props: {
      product_area: PRODUCT_AREA.HOME_SCREEN,
      screen_name: SCREEN_NAMES.HOME_SCREEN,
      detail: 'drop down menu',
    },
  };

  return (
    <>
      <UserDropdownComponent
        label={data.userFirstName.toUpperCase()}
        dropdownItemsConfig={data.menuItemsConfig.map(
          (menuItems) => menuItems.items,
        )}
        userBarAnalyticsProps={userDropdownAnalytics}
        userBarProps={{
          buttonProps: {
            css: {
              backgroundColor: '$surfaceCardSecondary',
            },
          },
        }}
      />
      <AccountDeletionModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
});
