import { forwardRef } from 'react';
import mergeRefs from 'merge-refs';
import { useFormContext } from '@leagueplatform/web-common';
import { useRequiredErrorMessage } from 'hooks/use-required-error-message.hook';
import { GDSCheckboxProps, Checkbox } from '@leagueplatform/genesis-core';
import { RHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';
import { ariaDescribedbyProp } from 'common/utils/get-ariadescribedby-prop';
import type { MergeWithRHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';

export const RHFCheckbox = forwardRef<
  HTMLInputElement,
  MergeWithRHFInputWrapper<GDSCheckboxProps>
>(
  (
    {
      id,
      label,
      hint,
      required,
      registerOptions,
      onChange,
      ...restComponentProps
    },
    ref,
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const requiredErrorMessage = useRequiredErrorMessage(label);

    const { ref: RHFRegisterRef, ...restRHFRegisterProps } = register(id, {
      ...registerOptions,
      required: required && requiredErrorMessage,
      ...(onChange && { onChange }),
    });

    return (
      <RHFInputWrapper id={id} hint={hint} required={required}>
        <Checkbox
          label={label}
          id={id}
          {...(errors?.[id]?.message && { inputStatus: 'error' })}
          {...ariaDescribedbyProp({
            id,
            error: !!errors?.[id]?.message,
            hint,
          })}
          {...restComponentProps}
          {...restRHFRegisterProps}
          ref={mergeRefs(RHFRegisterRef, ref)}
        />
      </RHFInputWrapper>
    );
  },
);
