import { DATE_FORMAT } from '@leagueplatform/web-common';

export const UTC_DATE_FORMAT = { ...DATE_FORMAT, timeZone: 'UTC' };

export const APP_ID = 'scan-web';
export const TENANT_ID = 'scan';
export const ROUTE_PATHS = {
  CONTACT: '/contact',
  HOME: '/home',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  ACCOUNT_INFO: '/settings/account-information',
  COMMUNICATION_PREFERENCES: '/communication-preferences',
  ABOUT: '/about',
  DOCUMENT_PREFERENCES: () =>
    `${ROUTE_PATHS.COMMUNICATION_PREFERENCES}/document-preferences`,
  CHANGE_DOCTOR: '/change-doctor',
  MEMBER_REDIRECT: '/member',
  PRIOR_AUTHORIZATIONS: '/prior-authorizations',
  PRIOR_AUTHORIZATIONS_DETAILS: () =>
    `${ROUTE_PATHS.PRIOR_AUTHORIZATIONS}/:authorizationId`,
};

export const LOCAL_STORAGE_KEYS = {
  FEATURE_HIGHLIGHTS_COMPLETED: 'feature_highlights_completed',
};

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scan-web',
};

export const COMMUNICATION_METHOD_CODES = {
  ONLINE: 'email',
  MAIL: 'mail',
  NONE: 'none',
};

// SCAN marketing page - same for all environments
export const POST_LOGOUT_URL = 'https://www.scanhealthplan.com';

// Same URL for all links
export const TERMS_OF_USE_URL = 'https://www.scanhealthplan.com/legal-notices';
export const PRIVACY_POLICY_URL =
  'https://www.scanhealthplan.com/legal-notices';
export const NON_DISCRIMINATION_POLICY_URL =
  'https://www.scanhealthplan.com/legal-notices';
export const DISCLAIMER_URL = 'https://www.scanhealthplan.com/legal-notices';
export const MESSAGE_TYPES = {
  LINK_EMAIL: 'link_email',
};
