// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.1.0.

/*
 * Following tokens have been deprecated in version 1.1.0, please update your theme:
 *
 * Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
 * 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
 * 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
 * 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
 * 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
 * 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
 * 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
 * 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
 * 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
 * 'Pill' is deprecated. Please use 'Circle' instead.
 */

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const scanHealthTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FEFFF6FF',
        secondary: '#D3EDFBFF',
        tertiary: '#FAF5A5FF',
      },
      card: {
        primary: '#FFFFFFFF',
        secondary: '#FFFFFFFF',
        disabled: '#F2EDEAFF',
      },
    },
    onSurface: {
      text: {
        primary: '#140101FF',
        subdued: '#494340FF',
        reversed: '#FFFFFFFF',
        critical: '#C63030FF',
        success: '#437038FF',
        warning: '#CE8A47FF',
      },
      border: { subdued: '#140101FF', default: '#494340FF' },
      icon: { primary: '#140101FF' },
    },
    interactive: {
      action: {
        primary: '#140101FF',
        hovered: '#FD2324FF',
        pressed: '#C12223FF',
        subdued: '#776B65FF',
        disabled: '#A59891FF',
      },
      icon: { default: '#FFFFFFFF', disabled: '#A59891FF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F2EDEAFF',
        hovered: '#E2D9D4FF',
        pressed: '#A59891FF',
      },
      border: {
        default: '#140101FF',
        hovered: '#332F2DFF',
        disabled: '#776B65FF',
        critical: { default: '#C63030FF' },
      },
      focus: { inner: '#140101FF', outer: '#140101FF' },
    },
    critical: {
      background: { subdued: '#FFB7B7FF', secondary: '#F9D2D4FF' },
      border: { default: '#C63030FF' },
      icon: '#C63030FF',
    },
    warning: {
      background: { subdued: '#FFD092FF', secondary: '#FFFFFFFF' },
      border: { default: '#CE8A47FF' },
      icon: '#FFAB58FF',
    },
    success: {
      background: { subdued: '#AAD2A0FF', secondary: '#ECF4DDFF' },
      border: { default: '#437038EE' },
      icon: '#437038FF',
    },
    highlight: {
      background: { subdued: '#D3EDFBFF', secondary: '#EAF6FDEE' },
      border: { default: '#7DB5DEFF' },
      icon: '#7DB5DEFF',
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#242120FF',
          dark: '#494340FF',
          default: '#776B65FF',
          bright: '#C6B9B2FF',
          brightest: '#E2D9D4FF',
          pastel: '#F2EDEAFF',
        },
        secondary: {
          darkest: '#A69117FF',
          dark: '#D1B92FFF',
          default: '#FFE447FF',
          bright: '#FAF5A5FF',
          brightest: '#FAF5A5FF',
          pastel: '#FFFEEEFF',
        },
        tertiary: {
          darkest: '#B46779FF',
          dark: '#DC96A7FF',
          default: '#FFC2D1FF',
          bright: '#FBE5EBFF',
          brightest: '#FDEFF2FF',
          pastel: '#FEF7F9FF',
        },
      },
    },
    primary: {
      background: {
        default: '#140101FF',
        hovered: '#FD2324FF',
        pressed: '#C12223FF',
        critical: {
          default: '#C63030FF',
          hovered: '#F9D2D4FF',
          pressed: '#C12223FF',
        },
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } },
    },
    secondary: {
      background: {
        default: '#FEFFF6FF',
        hovered: '#140101FF',
        pressed: '#494340FF',
      },
      text: { default: '#140101FF', hovered: '#FFFFFFFF' },
      border: { default: '#140101FF' },
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#FFFFFF00',
        pressed: '#FFFFFF00',
      },
      text: { default: '#140101FF', hovered: '#332F2D' },
      border: { default: '#FFFFFF00' },
    },
    input: {
      border: {
        success: '#7ACC65EE',
        warning: '#FFAB58FF',
        default: '#140101FF',
        hovered: '#332F2DFF',
        disabled: '#776B65FF',
        critical: '#C63030FF',
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#F2EDEAFF',
        hovered: '#E2D9D4FF',
        pressed: '#A59891FF',
      },
    },
  },
  shadows: {
    card: { x: 0, y: 2, blur: 8, spread: 0, color: '#A5989152' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' },
  },
  typography: {
    headingOne: {
      fontFamily:
        "ScanSans, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 40,
      fontSize: 32,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'uppercase',
      textDecoration: 'none',
    },
    headingTwo: {
      fontFamily:
        "ScanSans, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 32,
      fontSize: 24,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'uppercase',
      textDecoration: 'none',
    },
    headingThree: {
      fontFamily:
        "ScanSans, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 28,
      fontSize: 20,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'uppercase',
      textDecoration: 'none',
    },
    headingFour: {
      fontFamily:
        "ScanSans, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 18,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'uppercase',
      textDecoration: 'none',
    },
    subtitleOne: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    subtitleTwo: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOne: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyOneSecondary: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwo: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    bodyTwoSecondary: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonOne: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 24,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    buttonTwo: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: 20,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    label: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
    overline: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'uppercase',
      textDecoration: 'none',
    },
    caption: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 4,
      textCase: 'none',
      textDecoration: 'none',
    },
    tab: {
      fontFamily:
        "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none',
    },
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80,
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 30,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000,
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.5,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625,
    },
    lineHeights: {
      headingOne: 2.5,
      headingTwo: 2,
      headingThree: 1.75,
      headingFour: 1.5,
      subtitleOne: 1.5,
      subtitleTwo: 1.25,
      bodyOne: 1.5,
      bodyOneSecondary: 1.5,
      bodyTwo: 1.25,
      bodyTwoSecondary: 1.25,
      buttonOne: 1.5,
      buttonTwo: 1.25,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75,
    },
    baseSize: 16,
  },
  fonts: {
    headings:
      "ScanSans, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body: "Inter, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  },
  fontWeights: {
    bold: 700,
    semibold: 600,
    medium: 500,
    regular: 400,
  },
  transitions: { defaultTime: '200ms' },
};
