// Masonry v1 or v1.5 containers
export const MASONRY_WS_CONTAINERS = {
  HOME_PAGE: 'scan_homepage',
  CARE_HOME_PAGE: 'scan_care',
};

// MASONRY 2.0 containers
export const MASONRY_ROOT_NODES = {
  AUTHORIZATIONS: 'authorizations$root',
  SECTION_ITEMS: 'authorizations$root-section-item',
  LEARN_MORE: 'authorizationLearnMore$root',
  AUTHORIZATION_DETAILS: 'authorizationDetails$root',
};

export const MASONRY_NAMESPACES = {
  DEFAULT: 'default',
  PRIOR_AUTHORIZATIONS_STATUS: 'scan_authorizations',
  PRIOR_AUTHORIZATION_STATUS_DETAILS: 'scan_authorization_details',
};

export const MASONRY_WIDGET_TYPES = {
  HERO_BANNER: 'heroBanner',
  AUTHORIZATION: {
    CARD: 'authorizationCard',
    LEARN_MORE: {
      CARD: 'authorizationLearnMoreCard',
      HEADER: 'authorizationLearnMoreHeader',
      DEFINITION: 'authorizationStatusLearnMoreDefinition',
    },
    DETAILS_HEADER: 'authorizationDetailsHeader',
    SERVICE: {
      CARD: 'authorizationServiceCard',
      INFO_SECTION: 'authorizationServiceInfoSection',
    },
  },
  RICH_TEXT: 'richtext',
  LOAD_MORE_BUTTON: 'loadMoreButton',
  PAGINATION_LAYOUT: 'paginationLayout',
};

export const MASONRY_ACTION_TYPES = {
  DEEPLINK: 'deeplink',
};
