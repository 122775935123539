import { useMutation, useQueryClient } from 'react-query';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { components } from 'api/gen/communication-preferences';
import { Options } from 'hooks/use-set-user-profile/use-set-user-profile.hook';
import { API_PATHS, MUTATION_KEYS, QUERY_KEYS } from 'common/constants/api';

export type CommunicationPreferencesUpdatePayload = {
  data: {
    attributes: {
      preferences: {
        id: string;
        name: string;
        description: string;
        preferredMethod: {
          communicationMethodCode: components['schemas']['communicationMethods']['communicationMethodCode'];
        };
        availableMethods: {
          communicationMethodCode: components['schemas']['communicationMethods']['communicationMethodCode'];
        }[];
        preferredTime?: string;
      }[];
    };
  };
};

export const usePatchCommunicationPreferences = (options?: Options) => {
  const { onSuccess, onError } = options || {};
  const queryClient = useQueryClient();

  const { mutateAsync: patchCommunicationPreferences, isLoading } = useMutation(
    async (variables: CommunicationPreferencesUpdatePayload) => {
      const response = await leagueFetch(API_PATHS.COMMUNICATION_PREFERENCES, {
        method: 'PATCH',
        body: JSON.stringify(variables),
      });

      if (!response?.ok) {
        throw new Error(
          `Error Response Code ${response?.status}: ${await response.text()}`,
        );
      }
    },
    {
      mutationKey: [MUTATION_KEYS.COMMUNICATION_PREFERENCES.PATCH],
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.COMMUNICATION_PREFERENCES]);
        queryClient.refetchQueries([QUERY_KEYS.COMMUNICATION_PREFERENCES]);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );

  return { patchCommunicationPreferences, isLoading };
};
