import snakecaseKeys from 'snakecase-keys';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { useQueryClient, useMutation } from 'react-query';
import { useCallback } from 'react';

export interface Options {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useUpdateUserProfile = (options?: Options) => {
  const { onSuccess, onError } = options || {};
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserProfileMutation, isLoading } = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );

  const updateUserProfile = useCallback(
    async (userProfile: any) => {
      const serializedBody = snakecaseKeys(userProfile);
      await updateUserProfileMutation(serializedBody);
    },
    [updateUserProfileMutation],
  );

  return { updateUserProfile, isLoading };
};
