import { forwardRef } from 'react';
import mergeRefs from 'merge-refs';
import { useFormContext } from '@leagueplatform/web-common';
import { GDSTextInputProps, TextInput } from '@leagueplatform/genesis-core';
import type { MergeWithRHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';

export const RHFHiddenInput = forwardRef<
  HTMLInputElement,
  MergeWithRHFInputWrapper<GDSTextInputProps>
>(({ id, registerOptions }, ref) => {
  const { register } = useFormContext();

  const { ref: RHFRegisterRef, ...restRHFRegisterProps } = register(id, {
    ...registerOptions,
  });

  return (
    <TextInput
      css={{ display: 'none' }}
      type="hidden"
      id={id}
      {...restRHFRegisterProps}
      ref={mergeRefs(RHFRegisterRef, ref)}
    />
  );
});
