import {
  MasonryDriverActionController,
  MasonryDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
  MASONRY_ACTION_TYPES,
} from 'common/constants/masonry';
import { AuthorizationCard } from 'components/prior-authorization-status/renderers/authorization-card.component';
import { PaginationLayout } from 'components/prior-authorization-status/renderers/pagination-layout.component';

// TODO remove once all renderers are complete
const PlaceholderComponent = (props: any) => {
  const { nodeId } = props;
  // console.log(nodeId, props);
  return nodeId;
};

export const initPriorAuthorizationStatusRegistry = () => {
  // STATUS PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.CARD,
    AuthorizationCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.CARD,
    PlaceholderComponent, // TODO implement SCAN-871
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.HEADER,
    PlaceholderComponent, // TODO implement SCAN-871
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.DEFINITION,
    PlaceholderComponent, // TODO implement SCAN-871
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.RICH_TEXT,
    PlaceholderComponent, // TODO implement SCAN-871
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.LOAD_MORE_BUTTON,
    PlaceholderComponent, // TODO implement SCAN-871
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    MASONRY_WIDGET_TYPES.PAGINATION_LAYOUT,
    PaginationLayout,
  );

  MasonryDriverActionController.registerHandler(
    MASONRY_ACTION_TYPES.DEEPLINK,
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATIONS_STATUS,
    () => {},
  );

  // DETAILS PAGE
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.DETAILS_HEADER,
    PlaceholderComponent, // TODO implement SCAN-872
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.SERVICE.CARD,
    PlaceholderComponent, // TODO implement SCAN-872
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.PRIOR_AUTHORIZATION_STATUS_DETAILS,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.SERVICE.INFO_SECTION,
    PlaceholderComponent, // TODO implement SCAN-872
  );
};
