import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { components } from 'api/gen/communication-preferences';
import { ROUTE_PATHS } from 'common/constants/constants';
import { useDirtyFormHandler } from 'hooks/use-dirty-form-handle/use-dirty-form-handle.hook';
import { normalizeFieldName } from 'hooks/use-get-communication-preferences/use-get-communication-preferences.hook';
import {
  CommunicationPreferencesUpdatePayload,
  usePatchCommunicationPreferences,
} from 'hooks/use-patch-communication-preferences/use-patch-communication-preferences.hook';

export type CommunicationPreferencesSubmitVariables = {
  [
    key: string
  ]: components['schemas']['communicationMethods']['communicationMethodCode'];
};

export const useSubmitCommunicationPreferences = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { removeDirtyForm } = useDirtyFormHandler(
    formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' }),
  );
  const { patchCommunicationPreferences, isLoading } =
    usePatchCommunicationPreferences({
      onSuccess: () => {
        removeDirtyForm();
        history.push(ROUTE_PATHS.COMMUNICATION_PREFERENCES);
        toastActions.add({
          type: TOAST_STATUS.SUCCESS,
          text: formatMessage(
            { id: 'STR_FORM_SUCCESS' },
            {
              formName: formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' }),
            },
          ),
        });
      },
      onError: () => {
        toastActions.add({
          type: TOAST_STATUS.ERROR,
          text: formatMessage(
            { id: 'STR_FORM_ERROR' },
            {
              formName: formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' }),
            },
          ),
        });
      },
    });

  const submitCommunicationPreferences = async (
    communicationPreferencesData: components['schemas']['getCommunicationPreferencesSchema']['data'],
    values: CommunicationPreferencesSubmitVariables,
  ) => {
    const allPreferences: CommunicationPreferencesUpdatePayload['data']['attributes']['preferences'] =
      [];

    communicationPreferencesData?.forEach((section) => {
      const { attributes: sectionAttributes } = section;
      const { preferences: sectionPreferences } = sectionAttributes;

      sectionPreferences?.forEach((preference) => {
        const { name, description, availableMethods } = preference.attributes;

        const preferenceFieldKey =
          sectionAttributes.sectionName && name
            ? normalizeFieldName(sectionAttributes.sectionName, name)
            : '';

        const selectedPreferredMethod = values[preferenceFieldKey];

        if (name && description && availableMethods) {
          allPreferences.push({
            id: preference.id,
            name,
            description,
            preferredMethod: {
              communicationMethodCode: selectedPreferredMethod,
            },
            availableMethods: availableMethods?.map((method) => ({
              communicationMethodCode: method.communicationMethodCode,
            })),
          });
        }
      });
    });

    const payload = {
      data: {
        attributes: {
          preferences: allPreferences,
        },
      },
    };

    await patchCommunicationPreferences(payload);
  };

  return { submitCommunicationPreferences, isLoading };
};
