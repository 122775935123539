import type { DropdownSection } from '@leagueplatform/web-common-components';
import {
  ROUTE_PATHS,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
} from 'common/constants/constants';
import type { AppLocales, FormLinkouts, Link } from 'common/types/types';
import { useIntl } from '@leagueplatform/locales';
import { getBaseLocale } from 'hooks/use-footer-config/use-footer-config.hook';
import ExternalLinkIcon from '../../assets/external-link-icon.png';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> &
  Link)[];

type UserDropdownItems = {
  heading?: string;
  items: Link[];
};

const formLinkouts: Record<AppLocales, FormLinkouts> = {
  en: {
    autoPayRequest:
      'https://www.scanhealthplan.com/members/account/support/request-a-scan-form',
    disenrollmentForm:
      'https://www.scanhealthplan.com/-/media/scan/documents/resources/member-forms/disenrollment-form.pdf#zoom=100',
    phiRequestForm:
      'https://www.scanhealthplan.com/-/media/scan/documents/resources/member-forms/phi-request-form.pdf#zoom=100',
    grievancesAndAppeals:
      'https://www.scanhealthplan.com/members/account/support/appeals-grievances-and-exceptions-report',
    fiveWishes:
      'https://www.scanhealthplan.com/caregivers-and-family/advance-care-planning',
  },
  zh: {
    autoPayRequest: '#',
    disenrollmentForm: '#',
    phiRequestForm: '#',
    grievancesAndAppeals: '#',
    fiveWishes: '#',
  },
  es: {
    autoPayRequest: '#',
    disenrollmentForm: '#',
    phiRequestForm: '#',
    grievancesAndAppeals: '#',
    fiveWishes: '#',
  },
  ko: {
    autoPayRequest: '#',
    disenrollmentForm: '#',
    phiRequestForm: '#',
    grievancesAndAppeals: '#',
    fiveWishes: '#',
  },
  vi: {
    autoPayRequest: '#',
    disenrollmentForm: '#',
    phiRequestForm: '#',
    grievancesAndAppeals: '#',
    fiveWishes: '#',
  },
};

export const useUserDropdownConfig = (
  handleDeleteAccount: () => void,
): UserDropdownItems[] => {
  const { formatMessage } = useIntl();
  const baseLocale = getBaseLocale(formLinkouts);
  const {
    autoPayRequest,
    disenrollmentForm,
    phiRequestForm,
    grievancesAndAppeals,
    fiveWishes,
  } = formLinkouts[baseLocale];

  return [
    {
      // Account
      heading: formatMessage({ id: 'STR_ACCOUNT' }),
      items: [
        {
          // Account Information
          text: formatMessage({ id: 'STR_ACCOUNT_INFO' }),
          to: ROUTE_PATHS.ACCOUNT_INFO,
        },
        {
          // Communication Preferences
          text: formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }),
          to: ROUTE_PATHS.COMMUNICATION_PREFERENCES,
        },
      ],
    },
    {
      heading: formatMessage({ id: 'STR_FORMS' }),
      items: [
        {
          text: formatMessage({ id: 'STR_AUTO_PAY_REQUEST' }),
          to: autoPayRequest,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_DISENROLLMENT_FORM' }),
          to: disenrollmentForm,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_PHI_REQUEST_FORM' }),
          to: phiRequestForm,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_GRIEVANCES_AND_APPEALS' }),
          to: grievancesAndAppeals,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
        {
          text: formatMessage({ id: 'STR_FIVE_WISHES' }),
          to: fiveWishes,
          target: '_blank',
          icon: ExternalLinkIcon,
        },
      ],
    },
    {
      // About
      heading: formatMessage({ id: 'STR_ABOUT' }),
      items: [
        {
          // About SCAN Health
          text: formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH' }),
          to: ROUTE_PATHS.ABOUT,
        },

        {
          // Privacy Policy
          text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
          to: PRIVACY_POLICY_URL,
        },
        {
          // Terms of Service
          text: formatMessage({ id: 'STR_TERMS_OF_SERVICE' }),
          to: TERMS_OF_USE_URL,
        },

        {
          // Delete your account
          text: formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' }),
          onClick: handleDeleteAccount,
        },
      ],
    },
    {
      items: [
        {
          to: ROUTE_PATHS.SIGN_OUT,
          text: formatMessage({ id: 'STR_SIGN_OUT' }),
          linkProps: {
            color: 'onSurface.text.critical',
          },
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];
};
